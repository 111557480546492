import React from 'react';

export default (
  <svg
    data-v-0c6f1a0a=""
    data-v-7e51e2d8=""
    width="16"
    height="21"
    viewBox="0 0 16 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      data-v-0c6f1a0a=""
      data-v-7e51e2d8=""
      d="M7.99929 8.0083V12.284M7.99929 8.0083C8.55157 8.0083 8.99929 7.56059 8.99929 7.0083C8.99929 6.45602 8.55157 6.0083 7.99929 6.0083M7.99929 8.0083C7.447 8.0083 6.99929 7.56059 6.99929 7.0083C6.99929 6.45602 7.447 6.0083 7.99929 6.0083M7.99929 8.0083V6.0083M14.9999 1.02344V12.5742C14.9999 13.5632 14.5125 14.4886 13.697 15.0481L7.99996 18.9564L2.30289 15.0481C1.48739 14.4886 1 13.5632 1 12.5742V1.02344H14.9999Z"
      stroke="#6C737F"
      strokeWidth="2"
    ></path>
  </svg>
);
