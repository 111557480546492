import {
  RadarQuadrant,
  TechRadarApi,
  TechRadarLoaderResponse,
} from '@backstage-community/plugin-tech-radar';
import { DiscoveryApi } from '@backstage/core-plugin-api';

interface DelavalTRFile {
  id: string;
  mode: string;
  name: string;
  path: string;
  type: string;
  data_uri?: string;
  enc_uri?: string;
}

const DEFAULT_PROXY_PATH = '/gitlabci/projects/798';
const REF = 'master';
const REPO_PATH = 'techradar';

export class DelavalTechRadar implements TechRadarApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly proxyPath: string;

  constructor(options: { discoveryApi: DiscoveryApi; proxyPath?: string }) {
    this.discoveryApi = options.discoveryApi;
    this.proxyPath = options.proxyPath ?? DEFAULT_PROXY_PATH;
  }

  async load(_: string | undefined): Promise<TechRadarLoaderResponse> {
    var data = this.getData();

    return data;
  }

  async getData(): Promise<TechRadarLoaderResponse> {
    var filesdata: Object[] = [];
    var fetches: Object[] = [];

    const proxyUrl = await this.getApiUrl();
    const filesUri = `${proxyUrl}/repository/tree\\?ref=${REF}&&path=${REPO_PATH}`;
    await fetch(filesUri.toString(), { method: 'GET' })
      .then(res => res.json())
      .then(treedata => {
        treedata.forEach(function (data: DelavalTRFile) {
          const encUri = encodeURIComponent(data.path);
          const dataUri = `${proxyUrl}/repository/files/${encUri}?ref=${REF}`;
          fetches.push(
            fetch(dataUri)
              .then(function (data: Response) {
                return data.json();
              })
              .then(function (file_obj) {
                var c = atob(file_obj.content);
                filesdata.push(JSON.parse(c));
              }),
          );
        });
      });

    return Promise.all(fetches).then(() => {
      return this.combine(filesdata);
    });
  }

  private rmdup(arr: RadarQuadrant[]) {
    var ids: Array<string> = [];
    var unique: RadarQuadrant[] = [];
    arr.forEach(element => {
      if (!ids.includes(element.id)) {
        ids.push(element.id);
        unique.push(element);
      }
    });
    return unique;
  }

  combine(techr_data: Array<Object>): TechRadarLoaderResponse {
    var combined: TechRadarLoaderResponse = {
      entries: [],
      quadrants: [],
      rings: [],
    };
    techr_data.forEach(function (data: any) {
      combined.quadrants.push(...data.quadrants);
      combined.rings.push(...[data.ring]);
      combined.entries.push(...data.entries);
    });
    var unique: RadarQuadrant[] = this.rmdup(combined.quadrants);
    combined.quadrants = unique;
    combined.rings.sort((a, b) => a.id > b.id ? 1 : -1)
    return combined;
  }

  private async getApiUrl(): Promise<string> {
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');
    return proxyUrl + this.proxyPath;
  }
}
