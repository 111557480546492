import React from 'react';
import { InfoCard } from '@backstage/core-components';
import { HomePageToolkit } from '@backstage/plugin-home';

import DsmcIcon from './logos/dsmc';
import EiacIcon from './logos/eiac';
import DevOpsIcon from './logos/devops';
import HomeIcon from '@material-ui/icons/Home';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import { makeStyles, Theme } from '@material-ui/core/styles';

const HomeTemplate = {
  icon: <HomeIcon />,
  label: 'Homepage',
  path: '',
};

const DsmcTemplate = {
  icon: DsmcIcon,
  label: 'DSMC',
  path: 'dsmc',
};

const DevOpsDashboardTemplate = {
  icon: DevOpsIcon,
  label: 'DevOps Dashboard',
  path: 'devops',
};

const EiacTemplate = {
  icon: EiacIcon,
  label: 'EIAC',
  path: 'eiac-client/index.html',
};

const getLinkFromTemplate = (env: string, template: any): any => {
  return {
    icon: template.icon,
    label: template.label,
    url:
      env === "prod"
        ? `https://plus.delaval.com/${template.path}`
        : `https://${env}.plus.delaval.cloud/${template.path}`,
  };
};

const getToolsTab = (env: string) => {
  const tools = [
    getLinkFromTemplate(env, HomeTemplate),
    getLinkFromTemplate(env, DsmcTemplate),
    getLinkFromTemplate(env, DevOpsDashboardTemplate),
    getLinkFromTemplate(env, EiacTemplate),
  ];
  return (<HomePageToolkit title=" " tools={tools} />)
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
  env: string;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, env, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {getToolsTab(env)}
        </Box>
      )}
    </div>
  );
}



function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default () => {
const [value, setValue] = React.useState(0);
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));
const classes = useStyles();

const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    event;
    setValue(newValue);
  };
  return (
    <div className={classes.root}>
      <InfoCard title="Plus Portal - Quick Access">
      
        <AppBar position="static">
          <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
            <Tab label="prod" {...a11yProps(0)} />
            <Tab label="stage" {...a11yProps(1)} />
            <Tab label="test" {...a11yProps(2)} />
            <Tab label="dev" {...a11yProps(3)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0} env="prod">
        </TabPanel>
        <TabPanel value={value} index={1} env="stage">
        </TabPanel>
        <TabPanel value={value} index={2} env="test">
        </TabPanel>
        <TabPanel value={value} index={3} env="dev">
        </TabPanel>
      </InfoCard>
    </div>
  );
};
