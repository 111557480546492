import React from 'react';

export default (
  <svg
    data-v-0c6f1a0a=""
    data-v-7e51e2d8=""
    width="32px" 
    height="32px" 
    viewBox="0 0 32 32" 
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
  >
    <path
      data-v-0c6f1a0a=""
      data-v-7e51e2d8=""
      d="M4 5L4 27L28 27L28 5ZM6 7L26 7L26 9L6 9ZM6 11L26 11L26 25L6 25ZM11.219 13.781L9.781 15.219L12.563 18L9.781 20.782L11.219 22.219L14.719 18.719L15.406 18L14.719 17.282ZM16 20L16 22L22 22L22 20Z"
      stroke="#6C737F"
      strokeWidth="2"
    ></path>
  </svg>
);
