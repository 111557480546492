import React from 'react';
import { Table, TableColumn, SubvalueCell } from '@backstage/core-components';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {},
  empty: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
  },
}));

const data = [
  // DevOps Dashboards - Ops
  {
    title: 'Pipeline Inventory',
    link: 'https://plus.delaval.com/devops/inventories?navigationbar=false',
    subvalue: 'Ops',
    description: 'Pipeline information from GitLab',
  },
  {
    title: 'Deploy Timings',
    link: 'https://plus.delaval.com/devops/deploy-timings?navigationbar=false',
    subvalue: 'Ops',
    description:
      'All the deploys from the last 7 days and time differences between dev deploy and deploy to other environments',
  },
  {
    title: 'Recent changes',
    link: 'https://plus.delaval.com/devops/recent-changes?navigationbar=false',
    subvalue: 'Ops',
    description:
      'All GitLab projects and CloudFormation stacks with the timestamp of when the last deployment to the selected environment was executed',
  },
  // DevOps Dashboards - Tests
  {
    title: 'Monitoring Management',
    link: 'https://plus.delaval.com/devops/monitoringmanagement?navigationbar=false',
    subvalue: 'Tests',
    description:
      'Configure periodic execution and automatic monitoring with integration tests',
  },
  {
    title: 'Test Execution Reports',
    link: 'https://plus.delaval.com/devops/reports',
    subvalue: 'Tests',
    description: 'Automated test execution reports',
  },
  {
    title: 'Test Summary Report',
    link: 'https://plus.delaval.com/devops/testsummaryreport',
    subvalue: 'Tests',
    description: 'Automated test summary report',
  },
  // DevOps Dashboards - Governance
  {
    title: 'Repository Compliance',
    link: 'https://plus.delaval.com/devops/governance',
    subvalue: 'Governance',
    description:
      'Information about repositories and configurations necessary to enable integration with sonarqube',
  },
  {
    title: 'Repository Settings',
    link: 'https://plus.delaval.com/devops/governance',
    subvalue: 'Governance',
    description:
      'Project information from GitLab, such as IAC type and branch configuration',
  },
  {
    title: 'GitLab Project Summaries',
    link: 'https://plus.delaval.com/devops/summaries',
    subvalue: 'Governance',
    description: 'All GitLab projects and their descriptions',
  },
  {
    title: 'AWS Accounts',
    link: 'https://plus.delaval.com/devops/accounts',
    subvalue: 'Governance',
    description: 'All the active accounts in AWS',
  },
  // Grafana Governance
  {
    title: 'Non-Compliant Stacks',
    link: 'https://grafana.delaval.com/d/X2aetsS7k/non-compliant-stacks?orgId=1',
    subvalue: 'Governance',
    description:
      'List of CloudFormation stacks that does not meet the requirements',
  },
  {
    title: 'Non-Compliant Lambdas',
    link: 'https://grafana.delaval.com/d/I2GkhsS7k/non-compliant-api-endpoints',
    subvalue: 'Governance',
    description: 'List of non-compliant lambdas',
  },
  {
    title: 'Non-Compliant API endpoints',
    link: 'https://grafana.delaval.com/d/I2GkhsS7k/non-compliant-api-endpoints',
    subvalue: 'Governance',
    description: 'List of non-compliant API endpoints',
  },
  {
    title: 'Non-Compliant API endpoints for CORS',
    link: 'https://grafana.delaval.com/d/XzEYxvJnk/non-compliant-api-endpoints-for-cors?orgId=1',
    subvalue: 'Governance',
    description: 'List of API endpoints with incorrectly set CORS header',
  },
  // Grafana AWS
  {
    title: 'AWS Gateway',
    link: 'https://grafana.delaval.com/d/AWSAPIGat/aws-api-gateway?orgId=1',
    subvalue: 'AWS Monitoring',
    description:
      'Monitoring of AWS Gateway (dimensions: Account, ApiName, Stage)',
  },
  {
    title: 'AWS Lambda',
    link: 'https://grafana.delaval.com/d/AWSLambda/aws-lambda-v13?orgId=1',
    subvalue: 'AWS Monitoring',
    description: 'Monitoring of AWS Lambda (dimensions: Account, FunctionName)',
  },
];

export default () => {
  const classes = useStyles();
  const columns: TableColumn[] = [
    {
      title: 'Dashboard',
      width: '30%',
      cellStyle: { color: '#2FA5DC' },
      customFilterAndSearch: (
        query,
        row: any, // Only needed if you want subvalue searchable
      ) =>
        `${row.title} ${row.subvalue}`
          .toLocaleUpperCase('en-US')
          .includes(query.toLocaleUpperCase('en-US')),
      field: 'title',
      highlight: true,
      render: (row: any): React.ReactNode => (
        <a href={row.link} target="_blank">
          <SubvalueCell value={row.title} subvalue={row.subvalue} />
        </a>
      ),
    },
    {
      title: 'Description',
      field: 'description',
    },
  ];
  return (
    <div className={classes.container}>
      <Table
        options={{ paging: true }}
        data={data}
        columns={columns}
        title="Devops Dashboards"
      />
    </div>
  );
};
