import React from 'react';

export default (
  <svg
    data-v-0c6f1a0a=""
    data-v-7e51e2d8=""
    width="20"
    height="19"
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      data-v-0c6f1a0a=""
      data-v-7e51e2d8=""
      d="M9.99995 8.99964H18.9999V1.00049H1V8.99964H9.99995ZM9.99995 8.99964V13.015M12.0029 15.015C12.0029 16.1195 11.1075 17.015 10.0029 17.015C8.89834 17.015 8.00291 16.1195 8.00291 15.015C8.00291 13.9104 8.89834 13.015 10.0029 13.015C11.1075 13.015 12.0029 13.9104 12.0029 15.015ZM12.0029 15.015L17.9997 15.0154M7.99854 15.0156L2 15.0156M4 5.00049H5.8584M8 5.00049H9.8584"
      stroke="#6C737F"
      strokeWidth="2"
    ></path>
  </svg>
);
