import React from 'react';
import { HomePageToolkit } from '@backstage/plugin-home';

import AWSIconSVG from './logos/aws.svg';
import SlackIconSVG from './logos/slack.svg';
import ConfluenceIconSVG from './logos/confluence.svg';
import GitlabIconSVG from './logos/gitlab.svg';
import GrafanaIconSVG from './logos/grafana.svg';
import JiraIconSVG from './logos/jira.svg';
import LokiIconSVG from './logos/loki.svg';
import OpsgenieIconSVG from './logos/opsgenie.svg';
import SentryIconSVG from './logos/sentry.svg';
import SonarqubeIconSVG from './logos/sonarqube.svg';
import StorybookIconSVG from './logos/storybook.svg';
import SupportIconSVG from './logos/delaval.svg';
import CoverageIconSVG from './logos/coverage.svg';
import HelpIcon from '@material-ui/icons/Help';
import ArtifactoryIconSVG from './logos/artifactory.svg';
import AzureIcon from './logos/azure.svg';

const getToolkitLink = (icon: any, alt: string, url: string) => {
  const iconElement = (
    <img style={{ height: '3.5em', width: '3.5em' }} src={icon} alt={alt} />
  );
  return {
    label: alt,
    icon: iconElement,
    url: url,
  };
};

const AWS = getToolkitLink(
  AWSIconSVG,
  'AWS Console',
  'https://launcher.myapps.microsoft.com/api/signin/5da1c951-fcb4-495d-a73b-4361eff1f6f1?tenantId=be55e3d7-a296-4248-b38b-cbef3af2203a',
);

const Azure = getToolkitLink(
  AzureIcon,
  'Azure Portal',
  'https://portal.azure.com/',
);

const Slack = getToolkitLink(
  SlackIconSVG,
  'Slack',
  'https://welivemilk.slack.com/',
);

const Confluence = getToolkitLink(
  ConfluenceIconSVG,
  'Confluence',
  'https://delaval.atlassian.net/wiki/home',
);

const Gitlab = getToolkitLink(
  GitlabIconSVG,
  'Gitlab',
  'https://gitlab.delaval.cloud/',
);

const Grafana = getToolkitLink(
  GrafanaIconSVG,
  'Grafana',
  'https://grafana.delaval.cloud',
);

const Jira = getToolkitLink(
  JiraIconSVG,
  'Jira',
  'https://delaval.atlassian.net/jira/projects',
);

const Opsgenie = getToolkitLink(
  OpsgenieIconSVG,
  'Opsgenie',
  'https://delaval.app.eu.opsgenie.com/',
);

const Loki = getToolkitLink(
  LokiIconSVG,
  'Logs',
  'https://logs.delaval.cloud/_dashboards/app/home#/',
);

const CoverageSite = getToolkitLink(
  CoverageIconSVG,
  'Jest Coverage',
  'https://coverage.delaval.cloud/',
);

const DeveloperGuide = {
  icon: <HelpIcon style={{ height: '2.5em', width: '2.5em' }} />,
  label: 'Developer Guide',
  url: 'https://backstage.delaval.com/docs/default/component/delaval-blue-paths/developers%20guide/what_is_developer_guide/',
};

const Artifactory = getToolkitLink(
  ArtifactoryIconSVG,
  'JFrog Artifactory',
  'https://artifacts.prod.pd-se-site.com/ui/login',
);

const Sentry = getToolkitLink(
  SentryIconSVG,
  'Sentry',
  'https://sentry.io/auth/login/delaval-el/',
);

const Sonarqube = getToolkitLink(
  SonarqubeIconSVG,
  'Sonarqube',
  'https://sonarqube.delaval.cloud',
);

const Storybook = getToolkitLink(
  StorybookIconSVG,
  'Storybook',
  'https://dev.storybook.delaval.cloud/',
);

const Support = getToolkitLink(
  SupportIconSVG,
  'Help Center',
  'https://support.delaval.cloud/',
);

export default () => {
  return (
    <HomePageToolkit
      title="Developer Tools"
      tools={[
        AWS,
        Azure,
        Slack,
        Gitlab,
        Storybook,
        Confluence,
        Jira,
        Loki,
        Opsgenie,
        Sentry,
        Sonarqube,
        Grafana,
        CoverageSite,
        Support,
        DeveloperGuide,
        Artifactory
      ]}
    />
  );
};
