import { Content, Header, ItemCardGrid, ItemCardHeader, Page, Link } from '@backstage/core-components';
import { configApiRef, discoveryApiRef, useApi, identityApiRef} from '@backstage/core-plugin-api';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect, useState } from 'react';
import { CatalogClient, GetEntitiesResponse } from '@backstage/catalog-client';

const rcolors = [
        '#3932EF',
        '#7671F6',
        '#D2D1F7',
        '#9695BC',
        '#494691',
        '#8F8EA6',
        '#6B66F7',
        '#B36CE9',
        '#7A21BF',
        '#734695',
        '#2A8DE4',
        '#328CDB',
        '#0363B6',
        '#994EDB',
        '#B55FFF',
    ]

const useStyles1 = makeStyles(
    { header: {backgroundImage: ['linear-gradient(to bottom right,', rcolors[Math.floor(Math.random() * rcolors.length)],', ', rcolors[Math.floor(Math.random() * rcolors.length)],')'].join('')}},
);
const useStyles2 = makeStyles(
    { header: {backgroundImage: ['linear-gradient(to bottom right,', rcolors[Math.floor(Math.random() * rcolors.length)],', ', rcolors[Math.floor(Math.random() * rcolors.length)],')'].join('')}},
);
const useStyles3 = makeStyles(
    { header: {backgroundImage: ['linear-gradient(to bottom right,', rcolors[Math.floor(Math.random() * rcolors.length)],', ', rcolors[Math.floor(Math.random() * rcolors.length)],')'].join('')}},
);

const useStyles4 = makeStyles(
    { header: {backgroundImage: ['linear-gradient(to bottom right,', rcolors[Math.floor(Math.random() * rcolors.length)],', ', rcolors[Math.floor(Math.random() * rcolors.length)],')'].join('')}},
);

const useStyles5 = makeStyles(
    { header: {backgroundImage: ['linear-gradient(to bottom right,', rcolors[Math.floor(Math.random() * rcolors.length)],', ', rcolors[Math.floor(Math.random() * rcolors.length)],')'].join('')}},
);

const useStyles6 = makeStyles(
    { header: {backgroundImage: ['linear-gradient(to bottom right,', rcolors[Math.floor(Math.random() * rcolors.length)],', ', rcolors[Math.floor(Math.random() * rcolors.length)],')'].join('')}},
);

const useStyles7 = makeStyles(
    { header: {backgroundImage: ['linear-gradient(to bottom right,', rcolors[Math.floor(Math.random() * rcolors.length)],', ', rcolors[Math.floor(Math.random() * rcolors.length)],')'].join('')}},
);

const useStylesBpInfo = makeStyles((theme: Theme) =>
  createStyles({
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  }),
);

declare type bpMetadata = {
    name: string,
    description: string,
}

declare type bpSpec = {
    type: string,
    
}

interface bpConfigEntry {
    metadata: bpMetadata,
    spec: bpSpec,
}

type bpConfigEntries = bpConfigEntry[];


export const DelavalBluePath = () => {
    
    const [openBPInfo, setOpenBPInfo] = useState(false);
    
    const openDialog = () => {
        setOpenBPInfo(true);
      };
    
    const closeDialog = () => {
        setOpenBPInfo(false);
    };
    const header_classess = [useStyles1(), useStyles2(), useStyles3(), useStyles4(), useStyles5(), useStyles6(), useStyles7()];
    const bpInfo_class = useStylesBpInfo();
    const [Bp, setBp] = useState<bpConfigEntries>([]);
    
    const config = useApi(configApiRef);
    const backend_url = config.getString('backend.baseUrl');
    const app_url = config.getString('app.baseUrl');
    const gitlab_conf = config.getConfigArray('integrations.gitlab');
    const gitlab_url = gitlab_conf[0].get('host'); 
    const apiKey1 = config.getConfig('backend');
    const identityApi = useApi(identityApiRef);
    
    //TODO: this are attempts to use backstage api interfaces to get catalog entities instead calling REST API
    // const discoveryApi = useApi(discoveryApiRef);
    // const catalogClient = new CatalogClient({ discoveryApi });
    
    useEffect(() => {
        const fetchData = async () => {
            try {
            //TODO: this are attempts to use backstage api interfaces to get catalog entities instead calling REST API
            // const body = await catalogClient.getEntities();

            const token = (await identityApi.getCredentials()).token
            const result = await fetch([backend_url, '/api/catalog/entities\\?filter=metadata.tags=bluepath'].join(''), { headers: {"Authorization": `Bearer ${token}`}});
            const body = await result.json();
            setBp(body);

            } catch(err) {
                console.error(err)
              // error handling code
            } 
          }
        
        fetchData()
        
    }, []);


    if (Bp.length > 0) {
        return (
            <Page themeId='website'>
                <Header  type='Documentation' title="Welcome to Delaval Blue Paths"
                    subtitle="Opinionated and supported best practices for engineers in Digital Services"></Header>
                <Content>
                    
                <Button color="primary" variant="contained" onClick={openDialog}>
                    About
                </Button><span> </span>
                <Button color="default" variant="contained" href="create/templates/default/bluepath">
                    Create Blue Path...
                </Button> 
                <Dialog
                    open={openBPInfo}
                    onClose={closeDialog}
                    aria-labelledby="dialog-title"
                    aria-describedby="dialog-description"
                >
                    <DialogTitle id="dialog-title">
                        What are Delaval Blue Paths
                        <IconButton
                            aria-label="close"
                            className={bpInfo_class.closeButton}
                            onClick={closeDialog}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <ul>
                        <li>Blue path is a term used to describe the ideal flow or sequence of events that should happen when developing and deploying software. It is a way of thinking about the process of building and releasing software that emphasize the collaboration, continues delivery and automation.</li>
                        <li>To be more precise Blue path is the ‘opinionated and supported’ path to ‘build something’ (for example, build a backend service, put up a website, create a data pipeline)</li>
                        <li>The Blue Path tutorial is a step-by-step tutorial that walks you through this opinionated and supported path.</li>
                        </ul>
                        <p>Blue Paths are hosted as ordinary git <Link to={[gitlab_url, '/sre/bluepaths'].join('')}>repositories</Link> and any changes can be make as merge request to it. </p>
                        <p>New Blue Path document can be created using <Link to='/create/templates/default/bluepath'>template creator</Link></p>
                        <p>BluePath will be visible within <b>60 minutes</b> after creating repository.</p>
                    </DialogContent>
                </Dialog>
                <br></br>
                <br></br>
                <ItemCardGrid>
                    {
                        Bp.map(item => (
                            <Card key={item.metadata.name}>
                            <CardMedia>
                                <ItemCardHeader title={`${item.metadata.name}`} subtitle={item.spec.type } classes={{root: header_classess[Math.floor(Math.random() * header_classess.length)].header}} />
                            </CardMedia>
                            <CardContent>
                                {item.metadata.description}
                            </CardContent>
                            <CardActions>
                                <Button color="primary" href={[app_url, '/docs/default/component/', item.metadata.name].join('')}>
                                    More...
                                </Button>
                            </CardActions>
                            </Card>
                        ))}
                </ItemCardGrid>
                </Content>
            </Page>
        )}
    else {
        return (<p>Loading</p>)
    }
}