import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 28,
  },
  path: {
    fill: '#7df3e1',
  },
});

const LogoIcon = () => {
  const classes = useStyles();

  return (
    <svg
      className={classes.svg}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="15 -10 192.756 192.756"
    >
  
      <g fill-rule="evenodd" clip-rule="evenodd"><path d="M117.861 107.85c2.385 2.936 8.348 10.641 8.348 10.641s-5.963 7.705-8.348 10.639c-3.025 3.67-7.703 6.055-12.84 6.055-9.265 0-16.694-7.521-16.694-16.693 0-9.174 7.43-16.693 16.694-16.693 5.137-.002 9.815 2.383 12.84 6.051zM168.494 153.252c-8.99-1.008-16.695-5.41-24.398-13.391-1.102-1.193-2.66-2.844-4.221-4.678-2.109 2.66-4.127 5.045-5.502 6.787-4.221 5.137-8.623 8.988-15.777 11.281v.275h49.898v-.274z" fill="#28539c"/><path fill="#d35d35" d="M179.316 85.561h-26.599l-12.842 15.96 13.576 16.418 25.865-32.378z"/><path d="M134.465 94.55L93.831 24.198l-74.755 129.33h73.012v-.275c-12.566-3.484-24.766-17.244-24.766-34.762 0-20.822 16.877-37.607 37.699-37.607 11.557 0 21.371 4.862 29.076 13.942l.368-.276z" fill="#28539c"/></g>

    </svg>
  );
};

export default LogoIcon;
