/*
 * Copyright 2021 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {
  HeaderWorldClock,
  ClockConfig,
  HomePageStarredEntities,
} from '@backstage/plugin-home';
import { Content, Header, Page } from '@backstage/core-components';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import Toolkit from './Toolkit';
import QuickAccessPortal from './QuickAccessPortal';
import Dashboards from './Dashboards';
import { NewAnnouncementBanner } from '@k-phoen/backstage-plugin-announcements';

const clockConfigs: ClockConfig[] = [
  {
    label: 'UTC',
    timeZone: 'UTC',
  },
  {
    label: 'Stockholm',
    timeZone: 'Europe/Stockholm',
  },
  {
    label: 'New Delhi',
    timeZone: 'Asia/Kolkata',
  },
];

export const homePage = (
  <Page themeId="home">
    <Header title="DeLaval Developer Portal" pageTitleOverride="Home">
      <HeaderWorldClock clockConfigs={clockConfigs} />
    </Header>
    <Content>
      <Grid container justifyContent="center" spacing={8}>
        <Grid container item xs={12}>
          <Grid item md={12}>
            <NewAnnouncementBanner />
          </Grid>
          <Grid item xs={12} md={6}>
            <HomePageStarredEntities />
          </Grid>
          <Grid item xs={12} md={6}>
            <Toolkit />
            <QuickAccessPortal />
          </Grid>
          <Grid item xs={12} md={12}>
            <Dashboards />
          </Grid>
        </Grid>
      </Grid>
    </Content>
  </Page>
);
